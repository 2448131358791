.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-images {
  height: 650px;
  width: 100%;
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  z-index: 9999;
  top: 50%;
}
.carousel-caption h1 {
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
  font-family: sans-serif;
}
.carousel-caption p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin: 15px 0;
}

.selected-image {
  height: 500px !important;
  width: 450px !important;
}

.main-image {
  height: 500px;
  width: 95%;
  object-fit: cover;
}

.main-image-about {
  height: 550px;
  width: 550px;
  object-fit: cover;
}

.text-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.images{
  height: 350px;
}

@media (max-width: 500px) {
  .carousel-caption {
    left: 0;
    right: 0;
  }

  .footer-icons {
    width: 50px !important;
  }
  .footer-links {
    width: 50px;
    display: flex;
  }

  .service-about{
    padding-left: 10px;
    padding-right: 10px;
  }
  .service-right{
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 10px;
  }
  .header-images{
    height: 450px;
  }

  .footer-text{
    width: 100% !important;
  }
}
